import { useTheme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Slider from 'react-slick';
import HomeView from '../../constants/HomeView';
import { RootState } from '../../models/RootState';
import './Home.scss';
// import Banner from '../../assets/images/common/Banner.png';
import { useHistory, useLocation } from 'react-router';
import TabPanel from '../../components/TabPanel/TabPanel';
import { BRAND_NAME } from '../../constants/Branding';
import {
  CASINO_MOB_NAV_PROVIDERS,
  CASINO_WEB_NAV_PROVIDERS,
  EZUGI_PROVIDERS,
} from '../../constants/CasinoView';
import { AuthResponse } from '../../models/api/AuthResponse';
import { GapGame } from '../../models/dc/DcGame';
import {
  FatchLiveCasinoGames,
  handleSearchGame,
  setSearchGame,
} from '../../store';
import SVLS_API from '../../svls-api';
import Copyright from '../Copyright/Copyright';
import NotificationTab from '../Notification-tab/NotificationTab';
import CardView from './CardView';

const WebBanners = HomeView.HOME_PAGE_WEB_BANNERS;
const tabsList = HomeView.CARDS_SLIDER_IMAGES;

type StoreProps = {
  loggedIn: boolean;
  allowedConfig: number;
  scroll: boolean;
  FatchLiveCasinoGames: (
    type: string,
    providerName: string,
    subProviderName: string
  ) => void;
  liveCasinoGames: GapGame[];
  setSearchGame: Function;
  searchGame: string;
  handleSearchGame: () => void;
};

const Home: React.FC<StoreProps> = (props) => {
  const history = useHistory();
  const {
    allowedConfig,
    scroll,
    FatchLiveCasinoGames,
    liveCasinoGames,
    setSearchGame,
    searchGame,
    handleSearchGame,
  } = props;
  const [casinoGameTabValue, setCasinoGameCatTabValue] = useState<number>(0);
  const [filteredProvider, setFilteredProvider] = useState<string>('');
  const [filteredAllProvider, setAllProvider] = useState<string>('');
  const [filteredPopularProvider, setPopularProvider] = useState<string>('');
  const [providerList, setProviderList] = useState<string[]>();
  const [filteredGames, setFilteredGames] = useState([]);
  const [liveGames, setLiveGames] = useState([]);
  const popularProviders = [
    'EZUGI',
    'Evolution Gaming',
    'Virtual Sports',
    'Royal Gaming',
  ];

  let location = useLocation();
  const [gamefilter, setGameFilter] = useState('');
  const [apiWebBanners, setApiWebBanners] = useState([]);
  const [apiMobBanners, setApiMobBanners] = useState([]);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setGameFilter(location.pathname.split('/home/')[1]);
  }, [location]);

  useEffect(() => {
    // Fetch Casino games only for fiesta provider
    FatchLiveCasinoGames('live-casino', 'Fiesta777', null);
    fetchBannerData();
  }, []);

  useEffect(() => {
    if (filteredAllProvider) {
      setPopularProvider('');
      setFilteredProvider(filteredAllProvider);
    }
  }, [filteredAllProvider]);

  useEffect(() => {
    if (filteredPopularProvider) {
      setAllProvider('');
      setFilteredProvider(filteredPopularProvider);
    }
  }, [filteredPopularProvider]);

  const getBanners = () => {
    return WebBanners.filter((m) => (m.configPermission & allowedConfig) !== 0);
  };

  const settings = {
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,
  };

  const theme = useTheme();
  let royalgames = liveCasinoGames.filter((g) => {
    return g.providerName === 'RG';
  });
  let slider;
  const next = () => {
    slider.slickNext();
  };
  const previous = () => {
    slider.slickPrev();
  };

  useEffect(() => {
    if (liveCasinoGames?.length > 0)
      setProviderList(
        liveCasinoGames
          .map((g) => g.subProviderName)
          .filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
      );
  }, [liveCasinoGames]);

  useEffect(() => {
    if (liveCasinoGames?.length > 0) {
      setLiveGames(
        liveCasinoGames.filter((i) => i.category.toLowerCase().includes('live'))
      );
    }
  }, [liveCasinoGames]);

  useEffect(() => {
    if (liveCasinoGames?.length > 0) {
      setFilteredGames(
        liveCasinoGames.filter((i) => filteredProvider == i.subProviderName)
      );
    }
  }, [filteredProvider]);

  const fetchBannerData = async () => {
    let hostname: string[] = window.location.hostname.split('.');
    let DOMAIN_URL =
      hostname[hostname?.length - 2] + '.' + hostname[hostname.length - 1];
    //let DOMAIN_URL = 'hypexexch.com'
    let webdata = [];
    let mobiledata = [];
    try {
      const response: AuthResponse = await SVLS_API.get(
        `/account/v2/books/${DOMAIN_URL}/banners`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            status: 'active',
            type: '*',
            category: 'homebanner',
          },
        }
      );
      let data = response?.data?.banners;
      if (data?.length > 0) {
        data.map((item) => {
          if (item.deviceType === 'desktop') {
            webdata.push(item);
          } else if (item.deviceType === 'mobile') {
            mobiledata.push(item);
          }
        });
        setApiWebBanners(webdata);
        setApiMobBanners(mobiledata);
      }
      if (BRAND_NAME.toLocaleLowerCase() === 'chal247') {
        if (webdata.length == 0) {
          setApiWebBanners(EZUGI_PROVIDERS);
        }
        if (mobiledata.length == 0) {
          setApiMobBanners(EZUGI_PROVIDERS);
        }
      } else {
        if (webdata.length == 0) {
          setApiWebBanners(CASINO_WEB_NAV_PROVIDERS);
        }
        if (mobiledata.length == 0) {
          setApiMobBanners(CASINO_MOB_NAV_PROVIDERS);
        }
      }
    } catch (err) {
      if (BRAND_NAME.toLocaleLowerCase() === 'chal247') {
        if (webdata.length == 0) {
          setApiWebBanners(EZUGI_PROVIDERS);
        }
        if (mobiledata.length == 0) {
          setApiMobBanners(EZUGI_PROVIDERS);
        }
      } else {
        if (webdata.length == 0) {
          setApiWebBanners(CASINO_WEB_NAV_PROVIDERS);
        }
        if (mobiledata.length == 0) {
          setApiMobBanners(CASINO_MOB_NAV_PROVIDERS);
        }
      }
      console.log(err);
    }
  };

  const divRef: any = useRef();
  const scrollToElement = () => {
    const current: any = divRef;
    if (current !== null) {
      current?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToElement();
  }, [scroll]);

  return (
    <>
      <div ref={divRef} className="home-page-ctn">
        {/* <div className="page-banner-ctn">
          {
            BRAND_NAME.toLocaleLowerCase() === "chal247" ? (
              <Slider {...settings}>
                {apiWebBanners?.length > 0 &&
                  apiWebBanners.map((g, i) => {
                    return (
                      <>
                        <div className="slider-images">
                          <img
                            className="banner-images"
                            src={g?.publicUrl}
                            key={'g?.publicUrl' + i}
                            alt={g?.title}
                          />
                        </div>
                      </>
                    );
                  })}
              </Slider>
            ) : (
              <>
                <Slider {...settings} className="web-view">
                  {apiWebBanners?.length > 0 &&
                    apiWebBanners.map((g, i) => {
                      return (
                        <>
                          <div className="slider-images">
                            <img
                              className="banner-images"
                              src={g?.publicUrl}
                              key={'g?.publicUrl' + i}
                              alt={g?.title}
                              onClick={() => history.push(g.redirectionUrl)}
                            />
                          </div>
                        </>
                      );
                    })}
                </Slider>
                <Slider {...settings} className="mob-view">
                  {apiMobBanners?.length > 0 &&
                    apiMobBanners.map((g, i) => {
                      return (
                        <>
                          <div className="slider-images">
                            <img
                              className="banner-images"
                              src={g?.publicUrl}
                              key={'g?.publicUrl' + i}
                              alt={g?.title}
                              onClick={() => history.push(g.redirectionUrl)}
                            />
                          </div>
                        </>
                      );
                    })}
                </Slider>
              </>
            )
          }
          <NotificationTab />
        </div> */}
        <div className="home-content">
          {
            <TabPanel value={casinoGameTabValue} index={0}>
              <CardView
                liveCasinoGames={liveCasinoGames}
                heading="Trending Now"
              />{' '}
            </TabPanel>
          }
        </div>
        <Copyright />
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
    allowedConfig: state.common.allowedConfig,
    scroll: state.common.scroll,
    liveCasinoGames: state.indianCasino.allLiveCasinoGames,
    searchGame: state.indianCasino.searchGame,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    FatchLiveCasinoGames: (
      type: string,
      providerName: string,
      subProviderName: string
    ) => dispatch(FatchLiveCasinoGames(type, providerName, subProviderName)),
    setSearchGame: (data: string) => dispatch(setSearchGame(data)),
    handleSearchGame: () => dispatch(handleSearchGame()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonContent,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import { Switch } from 'react-router';
import UserPLStatement from '../pages/UserPLStatement/UserPLStatement';
import AccountStatementEventLevelView from '../pages/AccountStatement/AccountStatementEventLevel';
import Home from '../pages/Home/Home';
import InCasino from '../pages/Casino/InCasino/InCasino';
import CasinoNew from '../pages/Casino/CasinoNew/CasinoNew';
import SlotGames from '../pages/Casino/CasinoNew/SlotGames';
import VirtualGames from '../pages/Casino/CasinoNew/VirtualSports';
import WacsIframe from '../pages/Casino/WacsIframe/WacsIframe';
import DcIframe from '../pages/Casino/DcIframe/DcIframe';
import CasinoIframeNew from '../pages/Casino/CasinoIframeNew/CasinoIframeNew';
import Poker from '../pages/Poker/Poker';
import Header from '../components/Header/Header';
import SubHeader from '../views/SubHeader/SubHeader';
import MyBets from '../pages/MyBets/MyBets';
import UserRoute from '../hoc/UserRoute';
import MyWallet from '../pages/MyWallet/MyWallet';
import GameReport from '../pages/GameReport/GameReport';
import { RootState } from '../models/RootState';
import { connect } from 'react-redux';
import ResponsibleGambling from '../pages/ResponsibleGambling/ResponsibleGambling';
import TermsConditions from '../pages/TermsConditions/TermsConditions';
import PunterSettings from '../pages/PunterSettings/PunterSettings';
import MyProfile from '../components/MyProfile/MyProfile';
import HelpView from '../components/HelpView/HelpView';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import {
  enableCommission,
  fetchBalance,
  setAllowedConfig,
  setSearchGame,
} from '../store';
import './UserRouter.scss';
import { useInterval } from '../hooks/useInterval';
import API from '../api';
import '../assets/global_styles/marquee.scss';
import { useMediaQuery, useTheme } from '@material-ui/core';
import DashboardView from '../pages/DashboardView/DashboardView';
import { ALLOW_CASINO } from '../constants/CasinoPermission';
import { CONFIG_PERMISSIONS } from '../constants/ConfigPermission';
import { useLocation, useHistory } from 'react-router-dom';
import UserCommissionReport from '../pages/UserCommission/UserCommissionReport';
import UserCommissionBySportView from '../pages/UserCommission/UserCommissionBySportView';
import TVGamesView from '../pages/TvGames/TVGamesView';
import EzugiGamesTest from '../pages/Casino/CasinoNew/EzugiGamesTest';
import GapCasinoIframe from '../pages/Casino/CasinoIframeNew/GapCasinoIframe';
import PrivacyInfo from '../components/Footer/PrivacyInfo';
import ConditionInfo from '../components/Footer/ConditionInfo';
import Deposit from '../pages/Payment/Deposit';
import Withdrawal from '../pages/Payment/Withdrawal';
import TransactionRequest from '../pages/TransactionRequest/TransactionRequest';
import OtherGames from '../pages/Casino/CasinoNew/OtherGames';
import Reports from '../pages/Reports/Reports';
import ElectionPage from '../pages/Election/ElectionPage';
import FavouritesGames from '../pages/Casino/CasinoNew/FavouritesGames';
import IndianCasinoPage from '../pages/IndianCasinoPage/IndianCasinoPage';
import IndCasinoIframeNew from '../pages/IndianCasinoPage/IndCasinoIframe/IndCasinoIframe';
import { UserNotification } from '../models/Notification';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AdminNotification from '../components/AdminNotifications/AdminNotification';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import MobileHeader from '../components/Header/MobileHeader';
import MobileSubHeader from '../views/MobileSubHeader/MobileSubHeader';
import AccountStatement from '../pages/AccountStatement/AccountStatement';
import Footer from '../components/Footer/Footer';
import LiveCasino from '../pages/Casino/CasinoNew/LiveCasino';
import SVLS_API from '../svls-api';
import SportsProviderIframe from '../pages/SportsProvider/SportsProviderIframe';
import { EzugiChanges } from '../constants/WhitelabelConfig';
import { BRAND_DOMAIN, BRAND_NAME } from '../constants/Branding';
import { isIOS } from 'react-device-detect';
import MlobbyIframeNew from '../pages/Casino/MlobbyIframeNew/MlobbyIframeNew';
import Referral from '../components/Referral/Referral';
import { DomainConfig } from '../models/DomainConfig';
import { setDomainConfig } from '../store/common/commonActions';
import BonusStatement from '../pages/BonusStatement';
import TurnoverHistory from '../pages/BonusModule/TurnoverHistory';

// import WalletWithdraw from '../pages/MyWallet/Withdraw/WalletWithdraw';

type StateProps = {
  role: string;
  allowedConfig: number;
  commissionEnabled: boolean;
  status: number;
  loggedIn: boolean;
  fetchBalance: () => void;
  setAllowedConfig: (allowedConfig: number) => void;
  setEnableCommission: (commission: boolean) => void;
  setSearchGame: Function;
  setDomainConfig: (config: DomainConfig) => void;
};

const UserRouter: React.FC<StateProps> = (props) => {
  const {
    role,
    allowedConfig,
    status,
    loggedIn,
    fetchBalance,
    setAllowedConfig,
    setEnableCommission,
    setSearchGame,
    setDomainConfig,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [showNotificationModal, setShowNotificationModal] =
    useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;
  const [notifications, setNotifications] = useState<any[]>([]);
  const [index, setIndex] = useState<boolean>(false);
  const [config, setConfig] = useState<DomainConfig>({
    demoUser: false,
    signup: false,
    whatsapp: false,
    telegram: false,
    payments: false,
    bonus: false,
  });

  useEffect(() => {
    setSearchGame('');
  }, [location]);

  const redirectUser = () => {
    let redirectionURL = null;
    if ((allowedConfig & 31) === 0) {
      if (
        (allowedConfig & CONFIG_PERMISSIONS.sports) !== 0 &&
        pathName.includes('casino')
      ) {
        redirectionURL = '/home';
      } else if (
        (allowedConfig & CONFIG_PERMISSIONS.casino) !== 0 &&
        pathName.includes('exchange_sports')
      )
        redirectionURL = '/home';
    } else if (
      (allowedConfig & CONFIG_PERMISSIONS.casino) === 0 &&
      pathName.includes('casino')
    ) {
      redirectionURL = '/home';
    } else if (
      (allowedConfig & CONFIG_PERMISSIONS.sports) === 0 &&
      pathName.includes('exchange_sports')
    ) {
      redirectionURL = '/home';
    } else if (
      (allowedConfig & CONFIG_PERMISSIONS.indian_casino) === 0 &&
      pathName.includes('indian_casino')
    ) {
      redirectionURL = '/home';
    } else if (
      (allowedConfig & CONFIG_PERMISSIONS.live_casino) === 0 &&
      pathName.includes('/dc/')
    ) {
      redirectionURL = '/home';
    }

    if (redirectionURL) history.push(redirectionURL);
  };

  useEffect(() => {
    redirectUser();
  }, [pathName, allowedConfig, loggedIn]);

  useEffect(() => {
    getAllowedConfig();
    getDomainConfig();
  }, [loggedIn]);

  const getDomainConfig = async () => {
    try {
      const response = await SVLS_API.get(`/account/v2/books/${BRAND_DOMAIN}`);
      if (response.status === 200) {
        const dConfig: DomainConfig = {
          demoUser: response.data.demo_user_enabled,
          signup: response.data.signup_enabled,
          whatsapp: response.data.whatsapp_support,
          telegram: response.data.telegram_support,
          payments: response.data.payments_enabled,
          bonus: response.data.bonus_enabled,
        };
        setConfig(dConfig);
        setDomainConfig(dConfig);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllowedConfig = async () => {
    let response;
    if (!loggedIn) {
      response = await API.get('/user/allowed-config');
    } else {
      response = await API.get('/user/allowed-config', {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
    }
    if (response.status === 200) {
      setAllowedConfig(response?.data?.allowedConfig);
      setEnableCommission(response?.data?.commissionEnabled);
    }
  };

  useInterval(() => {
    if (loggedIn) {
      fetchBalance();
      fetchNotifications();
    }
  }, 1000 * 10);

  useEffect(() => {
    if (loggedIn) {
      fetchNotifications();
    }
  }, [loggedIn]);

  useInterval(() => {
    if (loggedIn) {
      fetchNotifications();
    }
  }, 1000 * 30);

  const fetchNotifications = async () => {
    const response = await SVLS_API.get('/catalog/v2/notifications/', {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
      params: {
        type: 'ALL',
      },
    });
    setNotifications(response.data);
  };

  const closeStlDialog = () => {
    setShowNotificationModal(false);
  };

  return (
    <>
      {status !== -1 && status === 4 ? (
        <Redirect to="/reset-password" />
      ) : status !== -1 && status === 2 ? (
        <Redirect to="/terms-and-conditions" />
      ) : role && role !== 'User' ? (
        <Redirect to="/access-redirect" />
      ) : !ALLOW_CASINO &&
        (window.location.pathname.includes('/casino') ||
          window.location.pathname.includes('/dc/gamev1.1/')) ? (
        <Redirect to="/access-redirect" />
      ) : (
        <>
          <IonApp className="ion-app">
            {!(
              window.location.pathname.includes('/gap_casino/game/') && isMobile
            ) ? (
              <>
                {!isMobile ? <Header></Header> : <MobileHeader></MobileHeader>}

                {!isMobile ? <SubHeader /> : <MobileSubHeader />}
              </>
            ) : null}

            <IonContent
              className="inner-scroll"
              scrollEvents={true}
              onIonScroll={($event) => {
                if ($event.detail.currentY > 200) {
                  setIndex(true);
                } else {
                  setIndex(false);
                }
              }}
            >
              <IonRefresher
                slot="fixed"
                onIonRefresh={() => window.location.reload()}
              >
                <IonRefresherContent refreshingSpinner="circular"></IonRefresherContent>
              </IonRefresher>
              <div className="router-ctn">
                <Switch>
                  <Route path="/home/:game" component={Home} exact={true} />
                  <Route path="/home" component={Home} exact={true} />
                  {/* <Route path="/live-casino" component={LiveCasino} exact={true} /> */}
                  {/* <Route path="/casino" component={CasinoNew} exact={true} /> */}
                  <Route
                    path="/cards"
                    component={IndianCasinoPage}
                    exact={true}
                  />
                  {/* <Route
                    path="/casino/:gameName"
                    component={CasinoNew}
                    exact={true}
                  /> */}
                  <Route
                    path="/slot_games"
                    component={SlotGames}
                    exact={true}
                  />
                  <Route
                    path="/ezugi"
                    component={EzugiGamesTest}
                    exact={true}
                  />
                  {/* <Route
                    path="/virtual/:game"
                    component={VirtualGames}
                    exact={true}
                  /> */}
                  <Route
                    path="/other_games"
                    component={OtherGames}
                    exact={true}
                  />

                  <Route path="/reports" component={Reports} />

                  <Route
                    path="/favourite"
                    component={FavouritesGames}
                    exact={true}
                  />

                  <Route
                    path="/election"
                    component={ElectionPage}
                    exact={true}
                  />
                  <Route
                    path="/providers"
                    component={TVGamesView}
                    exact={true}
                  />
                  {/* <Route
                    path="/indian_casino"
                    component={IndianCasinoPage}
                    exact={true}
                  /> */}

                  <Route
                    path="/indian_casino/live/:gameCode"
                    component={IndCasinoIframeNew}
                    exact={true}
                  />

                  {/* <Route
                    path="/in/casino/:gameCode"
                    component={InCasino}
                    exact={true}
                  /> */}
                  <Route path="/dc" component={CasinoNew} exact={true} />
                  <Route
                    path="/gap_casino"
                    component={GapCasinoIframe}
                    exact={true}
                  />
                  <Route path="/poker" component={Poker} exact={true} />
                  <Route path="/help" component={HelpView} exact={true} />
                  <Route
                    path="/resetPassword"
                    component={ResetPassword}
                    exact={true}
                  />
                  <Route
                    path="/wacs/:gameId"
                    component={WacsIframe}
                    exact={true}
                  />
                  <Route
                    path="/dc/game/:gamePath"
                    component={DcIframe}
                    exact={true}
                  />
                  <Route
                    path="/dc/gamev1.1/:gamePath"
                    component={CasinoIframeNew}
                    exact={true}
                  />
                  <Route
                    path="/gap_casino/game/:gamePath"
                    component={GapCasinoIframe}
                    exact={true}
                  />
                  <UserRoute
                    path="/transaction/deposit"
                    component={Deposit}
                    exact={true}
                  ></UserRoute>
                  <UserRoute
                    path="/transaction/withdraw"
                    component={Withdrawal}
                    exact={true}
                  ></UserRoute>
                  {/* <UserRoute
                    path="/my_transactions"
                    component={TransactionRequest}
                    exact={true}
                  ></UserRoute> */}
                  <Route
                    path="/account/dashboard"
                    render={() => <DashboardView />}
                  ></Route>
                  <Route
                    exact
                    path="/account"
                    render={() => <Redirect to="/account/dashboard" />}
                  />
                  <UserRoute
                    path="/account/account_statement"
                    component={AccountStatement}
                    exact={true}
                  ></UserRoute>
                  {/* <UserRoute
                    path="/bonus_statement"
                    component={BonusStatement}
                    exact={true}
                  ></UserRoute> */}
                  {/* <UserRoute
                    path="/bonus/turnover_history"
                    component={TurnoverHistory}
                    exact={true}
                  ></UserRoute> */}
                  <UserRoute
                    path="/market_account_statement"
                    component={AccountStatementEventLevelView}
                    exact={true}
                  ></UserRoute>
                  {/* <UserRoute
                    path="/account/my_bets"
                    component={MyBets}
                    exact={true}
                  ></UserRoute> */}
                  <UserRoute
                    path="/account/my_wallet"
                    component={MyWallet}
                    exact={true}
                  ></UserRoute>
                  {/* <UserRoute
                    path="/account/pl_statement"
                    component={UserPLStatement}
                    exact={true}
                  ></UserRoute> */}
                  <UserRoute
                    path="/account/game_report"
                    component={GameReport}
                    exact={true}
                  ></UserRoute>
                  <UserRoute
                    path="/account/commission_report"
                    component={UserCommissionReport}
                    exact={true}
                  ></UserRoute>
                  <UserRoute
                    path="/account/commission_report/:eventId"
                    component={UserCommissionBySportView}
                    exact={true}
                  ></UserRoute>
                  <UserRoute
                    path="/account/settings"
                    component={PunterSettings}
                    exact={true}
                  ></UserRoute>
                  <UserRoute
                    path="/account/profile"
                    component={MyProfile}
                    exact={true}
                  ></UserRoute>
                  {/* <UserRoute
                    path="/referral"
                    component={Referral}
                    exact={true}
                  ></UserRoute> */}
                  <UserRoute
                    path="/profile"
                    component={MyProfile}
                    exact={true}
                  ></UserRoute>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/home" />}
                  />
                  {/* <Route
                    path="/responsible-gambling"
                    render={() => <ResponsibleGambling />}
                  /> */}
                  {/* <Route
                    path="/terms-conditions"
                    render={() => <TermsConditions />}
                  /> */}
                  <Route path="/home/policy" render={() => <PrivacyInfo />} />
                  <Route
                    path="/home/term-conditions"
                    render={() => <ConditionInfo />}
                  />
                  {EzugiChanges[BRAND_NAME.toLocaleLowerCase()] ? (
                    <>
                      <Route
                        path="/premium_sports"
                        component={SportsProviderIframe}
                        exact={true}
                      />
                      <Route
                        path="/exchange"
                        component={SportsProviderIframe}
                        exact={true}
                      />
                    </>
                  ) : null}
                </Switch>
              </div>

              <Dialog
                open={showNotificationModal}
                onClose={closeStlDialog}
                aria-labelledby="Settlements Dialog"
                fullScreen={false}
                fullWidth={true}
                maxWidth="md"
                className="dialog-div"
              >
                {/* <DialogTitle className="stl-dialog-title">
                <div className="modal-title">
                  <MicIcon
                    className="mic-icon"
                    fontSize="inherit"
                    htmlColor="var(--ion-color-primary)"
                  />
                  All Announcements
                </div>
                <IconButton
                  className="close-btn"
                  onClick={() => setShowNotificationModal(false)}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle> */}
                <div className="dialog-body">
                  <AdminNotification
                    allowedConfig={allowedConfig}
                    loggedIn={loggedIn}
                  />
                </div>
              </Dialog>
            </IonContent>

            <div className="mob-view-footer">
              <Footer />
            </div>
          </IonApp>
          {isMobile || isIOS ? (
            <div className="mob-view">
              <MlobbyIframeNew />
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  if (!state.auth.loggedIn) {
    return {
      role: null,
      status: -1,
      allowedConfig: state.common.allowedConfig,
    };
  }
  let claim = state.auth.jwtToken.split('.')[1];
  const role = JSON.parse(window.atob(claim)).role;
  const status = JSON.parse(window.atob(claim)).sts;
  return {
    loggedIn: state.auth.loggedIn,
    role: role,
    status,
    allowedConfig: state.common.allowedConfig,
    commissionEnabled: state.common.commissionEnabled,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    setAllowedConfig: (allowedConfig: number) =>
      dispatch(setAllowedConfig(allowedConfig)),
    setDomainConfig: (config: DomainConfig) =>
      dispatch(setDomainConfig(config)),
    setEnableCommission: (commission: boolean) =>
      dispatch(enableCommission(commission)),
    setSearchGame: (data: string) => dispatch(setSearchGame(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRouter);
